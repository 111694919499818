import * as React from "react";
import { graphql } from "gatsby";
import ProductInfo from "../components/page-components/Products/ProductInfo/ProductInfoMerch";
import ProductImageSlider from "../components/page-components/Products/ProductImageSlider";
import RelatedProductsSlider from "../components/page-components/Products/RelatedProductsSlider";
import { Styling } from "../styles/templates/product-merch.styled";
import Seo from "../components/global/seo";

// markup
const ProductPage = ({ data, pageContext }) => {
  const product = data.wpProduct.acf_product_info.merch;
  const category = "merch";
  //console.log(data)
  return (
    <Styling>
      <Seo
        title={data.wpProduct.seo.title}
        description={data.wpProduct.seo.metaDesc}
      />
      <ProductImageSlider images={product.images} />
      <ProductInfo
        context={pageContext}
        product={product}
        category={category}
        title={data.wpProduct.title}
        databaseId={data.wpProduct.databaseId}
        trackQuantities={data.wpProduct.is_physical_product.trackQuantities}
      />
      {data.relatedProducts.nodes.length > 0 ? (
        <RelatedProductsSlider products={data.relatedProducts.nodes} />
      ) : (
        ""
      )}
    </Styling>
  );
};

export default ProductPage;

export const query = graphql`
  query ($slug: String!, $id: String!, $baseTitle: String!) {
    relatedProducts: allWpProduct(
      limit: 10
      filter: {
        id: { ne: $id }
        productCategories: {
          nodes: { elemMatch: { name: { eq: $baseTitle } } }
        }
      }
    ) {
      nodes {
        id
        title
        slug
        acf_product_info {
          merch {
            price
            images {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1500
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
    wpProduct(slug: { eq: $slug }) {
      seo {
        title
        metaDesc
      }
      title
      id
      databaseId
      slug
      is_physical_product {
        trackQuantities
      }
      acf_product_info {
        merch {
          accordion {
            title
            text
          }
          salePrice
          price
          sizes {
            quantityAvailable
            size
          }
          description
          briefProductDescription
          images {
            image {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 1500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;
