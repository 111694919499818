import React, { useState, useRef } from "react";
import { Styling } from "./styled.js";
import { AiOutlinePlus } from "react-icons/ai";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

export default function ProductInfo({
  context,
  product,
  category,
  title,
  databaseId,
  trackQuantities,
}) {
  const {
    accordion,
    description,
    briefProductDescription,
    price,
    audio,
    images,
    salePrice,
    sizes,
  } = product;

  const [qty, setQty] = useState(1);
  const [size, setSize] = useState(sizes[0].size);
  const [maxQty, setMaxQty] = useState();
  const sizesRef = useRef();

  const increaseQty = () => {
    setQty(qty + 1);
  };
  const decreaseQty = () => {
    if (qty > 1) {
      setQty(qty - 1);
    }
  };

  const setActive = (e) => {
    const target = e.target.closest("button");
    const allSize = Array.from(sizesRef.current.querySelectorAll("button"));

    allSize.map((size) => size.classList.remove("active"));
    target.classList.add("active");
  };

  const createSizeString = () => {
    let sizesString = "";
    sizes.map((size, index) => {
      if (index === sizes.length - 1) {
        sizesString = sizesString.concat(size.size, "");
      } else {
        sizesString = sizesString.concat(size.size, "|");
      }
    });

    return sizesString;
  };

  return (
    <Styling>
      <div className="price-wrapper">
        <span className={salePrice ? `on-sale price` : "price"}>
          £{price.toFixed(2)}
        </span>
        {salePrice ? (
          <span className="sale_price">£{salePrice.toFixed(2)}</span>
        ) : (
          ""
        )}
      </div>
      <div className="main_title">{title}</div>
      <div className="description">{description}</div>
      {sizes ? (
        <div className="sizes-wrapper" ref={sizesRef}>
          {sizes.map((size, index) => {
            const the_size = size.size;
            const qty = size.quantityAvailable;
            let className;
            let button_disabled = false;

            if (qty < 10) {
              className = "low-stock";
            }
            if (qty === null) {
              className = "out-of-stock";
              button_disabled = true;
            }

            return (
              <button
                className={className}
                disabled={button_disabled}
                onClick={(e) => {
                  setActive(e);
                  setMaxQty(size.quantityAvailable);
                  setSize(the_size);
                }}
                key={`button_${the_size}_${index}`}
              >
                {the_size}
              </button>
            );
          })}
        </div>
      ) : (
        ""
      )}
      <div className="qty-wrapper">
        <button disabled={qty === 1 ? true : false} onClick={decreaseQty}>
          -
        </button>
        <div>{qty > maxQty ? setQty(maxQty) : qty}</div>
        <button disabled={qty >= maxQty ? true : false} onClick={increaseQty}>
          +
        </button>
      </div>

      <button
        class="snipcart-add-item capsule_button black"
        data-item-id={`snipcart-${databaseId}`}
        data-item-price={salePrice ? salePrice.toFixed(2) : price.toFixed(2)}
        data-item-url={process.env.GATSBY_FRONTEND_URL + "/shop" + context.link}
        data-item-categories={"merch"}
        data-item-metadata={`{"trackQuantities": true, "isPlugin": false}`}
        data-item-description={briefProductDescription}
        data-item-image={images[0]?.image?.localFile?.publicURL}
        data-item-name={title}
        data-item-quantity={qty}
        data-item-max-quantity={maxQty}
        data-item-custom1-name="Size"
        data-item-custom1-options={createSizeString()}
        data-item-shippable={true}
        data-item-custom1-value={size}
      >
        Add to cart - £{salePrice ? salePrice.toFixed(2) : price.toFixed(2)}
      </button>

      <Accordion allowMultipleExpanded allowZeroExpanded>
        {accordion.map((accordianItem, index) => {
          return (
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <AiOutlinePlus />
                  {accordianItem.title}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>{accordianItem.text}</AccordionItemPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </Styling>
  );
}
